/**
 *
 * CustomForm
 *
 */
import React, { memo, Ref, useEffect } from 'react';
import {
  Form,
  Formik,
  FormikFormProps,
  FormikProps,
  useFormikContext,
} from 'formik';

interface Props extends FormikFormProps {
  onSubmit: (values, others) => void;
  validationSchema: any;
  initialValues?: any;
  children: React.ReactNode;
  className?: string;
  onChange?: (value: any) => void;
  enableReinitialize?: boolean;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  ref?: Ref<FormikProps<any>> | undefined;
}

export const CustomForm = memo((props: Props) => {
  const {
    onSubmit,
    initialValues,
    className,
    validationSchema,
    children,
    onChange,
    enableReinitialize = false,
    validateOnBlur = false,
    validateOnChange = false,
    ref,
  } = props;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      innerRef={ref}
    >
      <>
        {onChange && <FormObserver onChange={onChange} />}
        <Form className={className}>{children}</Form>
      </>
    </Formik>
  );
});

interface FormObserverProps {
  onChange?: (value: any, initialValues?: any) => void;
}

const FormObserver = ({ onChange }: FormObserverProps) => {
  const { values, errors } = useFormikContext();

  console.log('errors', errors);

  useEffect(() => {
    if (onChange) onChange(values);
  }, [values, onChange]);

  return null;
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from '../slice/Auth/types';

interface AuthApi {
  auth: AuthState;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL + '/services/etaxe/api/v1',
  prepareHeaders: (headers, api) => {
    const authState = api.getState() as AuthApi;

    if (authState.auth.isAuthenticated) {
      headers.set('Authorization', `Bearer ${authState.auth.access_token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result?.error?.status === 401) {
  //   const authState = api.getState() as AuthApi;

  //   // send refresh token to get new access token
  //   const refreshResult = await baseQueryWithoutToken(
  //     {
  //       url: 'partner-web/auth/refresh-token',
  //       method: 'POST',
  //       body: authState.auth,
  //     },
  //     api,
  //     extraOptions,
  //   );

  //   if (refreshResult?.data) {
  //     // store the new token
  //     api.dispatch(authActions.login(refreshResult?.data as AuthState));
  //     // retry the original query with new access token
  //     result = await baseQuery(args, api, extraOptions);
  //   } else {
  // api.dispatch(authActions.logout());
  // api.dispatch(rootApi.util.resetApiState());
  // window.location.href = '/';
  // showToast('error', 'Votre session a expiré, veuillez vous reconnecter');
  //   }
  // }

  return result;
};

export const rootApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: builder => ({}),
});

const baseQueryWithoutToken = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL + '/services/etaxe/api/v1',
});

export const rootApiWithoutToken = createApi({
  reducerPath: 'rootApiWithoutToken',
  baseQuery: baseQueryWithoutToken,
  tagTypes: [],
  endpoints: builder => ({}),
});

const baseQueryFlag = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_FLAG_BASE_URL + '/services/referential/api/v2',
});

export const rootApiFlag = createApi({
  reducerPath: 'rootApiFlag',
  baseQuery: baseQueryFlag,
  tagTypes: [],
  endpoints: builder => ({}),
});

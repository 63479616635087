/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

import { GlobalStyle } from '../styles/global-styles';

import { useTranslation } from 'react-i18next';
import { Login } from './pages/auth/Login/Loadable';
import { ForgotPassword } from './pages/auth/ForgotPassword/Loadable';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from './services/slice/Auth/selectors';
import { useGetCurrentUserQuery } from './services/api/ProfilApi';
import { LayoutDashboard } from './components/Layout/Loadable';
import { Users } from './pages/admin/Users/Loadable';
import { TaxTypes } from './pages/admin/TaxTypes/Loadable';
import { TaxGroups } from './pages/admin/TaxGroups/Loadable';
import { PaymentTaxTypes } from './pages/admin/PaymentTaxTypes/Loadable';
import { PaymentModes } from './pages/admin/PaymentModes/Loadable';
import { TraderTypes } from './pages/admin/TraderTypes/Loadable';
import { Trader } from './pages/admin/Trader/Loadable';
import { Maps } from './pages/admin/Maps';
import { FinishProcessForgotPassword } from './pages/auth/FinishProcessForgotPassword/Loadable';
import { UpdatePassword } from './pages/my-account/UpdatePassword';
import { Operations } from './pages/admin/Operations/Loadable';
import { TypeOfUser } from './services/api/ProfilApi/types';
import { Dashboard } from './pages/admin/Dashboard/Loadable';
import { Localities } from './pages/admin/Localities/Loadable';
import { InstantPayments } from './pages/admin/InstantPayments/Loadable';

export function App() {
  const { i18n } = useTranslation();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { data: currentUser, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated,
  });

  const foundRules = React.useCallback(
    (searchRole: string) => {
      return !!currentUser?.authorities.find(role => role === searchRole);
    },
    [currentUser?.authorities],
  );

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Lempo"
        defaultTitle="Lempo"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A Lempo application" />
      </Helmet>

      <Routes>
        {!isAuthenticated ? (
          <>
            <Route index path="/" element={<Login />} />
            <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
            <Route
              path="/reinitialisation-mot-de-passe"
              element={<FinishProcessForgotPassword />}
            />
          </>
        ) : (
          <Route path="/" element={<LayoutDashboard />}>
            <Route index element={<Dashboard />} />
            <Route path="maps" element={<Maps />} />
            <Route path="operations" element={<Operations />} />
            <Route
              path="gestion-des-commercants/:status?/:id?"
              element={<Trader />}
            />
            <Route path="operations" element={<Operations />} />
            <Route path="paiements-instantané" element={<InstantPayments />} />
            <Route
              path="/changement-mot-de-passe"
              element={<UpdatePassword />}
            />
            {foundRules(TypeOfUser.ADMIN) && (
              <>
                <Route path="type-de-commercant" element={<TraderTypes />} />
                <Route path="localite" element={<Localities />} />
                <Route
                  path="periodicite-paiement-taxe"
                  element={<PaymentTaxTypes />}
                />
                <Route path="utilisateurs" element={<Users />} />
                <Route
                  path="paiements-instantané"
                  element={<InstantPayments />}
                />
                <Route path="utilisateurs/:code" element={<Users />} />
                <Route path="type-de-taxe" element={<TaxTypes />} />
                <Route path="comptes-actifs" element={<TaxGroups />} />
              </>
            )}
          </Route>
        )}
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
